@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .without-ring {
    @apply outline-transparent ring-transparent focus:outline-none focus:!ring-0 focus:ring-transparent focus:ring-offset-0;
  }
}

body {
  @apply min-h-screen overflow-x-hidden bg-gray-50;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

body:not([data-focus-source="keyboard"]):focus {
  outline: none;
}

input[type="text"] {
  -webkit-appearance: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 2px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: rgba(50, 50, 50, 0.1);
  border-radius: 4px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, 0.4);
  border-radius: 4px;
}

.scrollbar-floating::-webkit-scrollbar-track {
  background-color: transparent;
}
